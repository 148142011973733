.footer {
  position: relative;
  padding: 40px 0;
}

.footer .frosty-glass {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/*  */
@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  /* NOW do stuff that requires filters to work */
  .footer .frosty-glass {
    /*  */
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.footer-content {
  position: relative;
  z-index: 1;
}

.footer-content h2 {
  position: relative;
  font-family: var(--secondary-font);
  color: var(--secondary-font-color);
  font-weight: 400;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 14px;
  border-radius: 10px;
  border: 1px solid whitesmoke;
  text-align: center;
}

.links-list {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  margin-top: 20px;
}

.links-list li {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.links-list li a {
  position: relative;
  overflow: hidden;
  line-height: 1;
  /* background-color: var(--secondary-color); */
  padding: 10px 14px;
  border-radius: 5px;
  border: 1px solid #919191;
}

.links-list li a::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0.5);
  background-color: var(--primary-color);
  /**/
  -webkit-transition-property: opacity, scale;
  -moz-transition-property: opacity, scale;
  -ms-transition-property: opacity, scale;
  -o-transition-property: opacity, scale;
  -webkit-transition: 0.3s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: 0.3s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: 0.3s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: 0.3s cubic-bezier(0.75, 0, 0.125, 1);
}

.links-list li a .icon {
  position: relative;
  color: white;
  z-index: 1;
}
/* .links-list li a:hover {
  background-color: var(--primary-color);
} */

.links-list li a:hover::before {
  opacity: 1;
  transform: scale(1);
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .footer .frosty-glass {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer-content h2 {
    font-size: 0.9rem;
    padding: 10px 10px;
  }

  .music-toggle-container {
    display: none;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

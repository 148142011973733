/* views */
.headline {
  font-size: 6rem;
  text-align: center;
  font-weight: 900;
  color: "white";
  line-height: 0.5;
}
.headline a {
  font-size: 1.5rem;
  color: white;
}

.sub-headline {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  color: var(--tertiary-font-color);
}
.body-copy {
  font-size: 1.2rem;
  font-weight: 600;
  max-width: 80%;
  text-align: center;
  color: var(--secondary-font-color);
}

/*  disable ios video default play button */
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/*  */

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .headline {
    font-size: 4rem;
    text-align: center;
    font-weight: 900;
    color: "white";
    line-height: 0.8;
  }
  .sub-headline {
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--tertiary-font-color);
  }

  .body-copy {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 10px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

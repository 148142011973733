.header {
  position: relative;
  z-index: 1;
  /*background-color: red;*/
}

.header-panel {
  margin-top: 50px;
  padding: 0 30px;
  /* background-color: blue; */
}

/* overrides viewport to keep it as row */
.header-panel .viewport {
  flex-direction: row;
  justify-content: space-between;
}

.header-title {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
  /*  */
  border-radius: 10px;
  padding: 10px 14px;
  background-color: rgba(0, 0, 0, 0.5);
}

/*  */
@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  /* NOW do stuff that requires filters to work */
  .header-title {
    /*  */
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.header-title .variant-1 {
  color: var(--primary-color);
}
.header-title .variant-2 {
  color: var(--secondary-color);
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .header-title {
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0);
    -webkit-backdrop-filter: initial;
    backdrop-filter: initial;
  }
  .header-panel {
    display: none;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

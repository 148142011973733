.nav {
  position: relative;
}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list li a {
  color: var(--secondary-font-color);
  font-weight: 700;
  font-size: 1rem;
  padding: 10px 14px;

  display: block;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

/*  */
@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  /* NOW do stuff that requires filters to work */
  .nav-list li a {
    /*  */
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
  }
}

.nav-list li a:hover {
  color: var(--primary-color);
}

.nav-list li a.active {
  pointer-events: none;
  color: var(--primary-color);
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
  .nav-list li {
    padding: 0px;
  }
  .nav-list li a {
    padding: 5px 7px;
    font-size: 0.9rem;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 320px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

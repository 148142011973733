/* override */
.viewport {
  flex-direction: column;
  justify-content: center;
}

.welcome-screen {
  position: fixed;
  overflow: hidden;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);

  /*  */
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
}

/*  */
@supports (backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(20px)) {
  /* NOW do stuff that requires filters to work */
  .welcome-screen {
    /*  */
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

.welcome-box {
  min-width: 300px;
  text-align: center;
  pointer-events: none;
}

.welcome-box .name {
  text-align: center;
  font-weight: "semi-bold";
  color: var(--tertiary-font-color);
  font-size: 1.2rem;
  display: none;
  pointer-events: none;
}

.welcome-box .headline-ws {
  text-align: center;
  font-weight: bold;
  color: "white";
  font-size: 2rem;
  /* text-transform: uppercase; */
  pointer-events: none;
}

.note {
  color: #919191;
  font-weight: 600;
  pointer-events: none;
}
.welcome-screen.hide {
  will-change: transform;
  transform: translateY(calc(-100% - 20px));
}

.welcome-btns {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  pointer-events: painted;
}
.welcome-btns button {
  margin: 10px;
}

.devider {
  width: 100%;
  border: 0;
  margin-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/*  */
.hooks-main {
  display: none;
}
.hooks-main > div {
  position: absolute;
  will-change: transform;
  opacity: 1;
}
.hooks-main .b-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(7px);
  opacity: 1;
}

.hooks-main > div:nth-child(1) {
  width: 30px;
  height: 30px;
}
.hooks-main > div:nth-child(2) {
  width: 25px;
  height: 25px;
}
.hooks-main > div:nth-child(3) {
  width: 20px;
  height: 20px;
}
.hooks-main > div:nth-child(4) {
  width: 15px;
  height: 15px;
}

.hooks-main > div:nth-child(1) .b-bg {
  background-color: var(--primary-color);
}
.hooks-main > div:nth-child(2) .b-bg {
  background-color: var(--secondary-color);
}
.hooks-main > div:nth-child(3) .b-bg {
  background-color: var(--tertiary-color);
}
.hooks-main > div:nth-child(4) .b-bg {
  background-color: var(--quaternary-color);
}

.hooks-main > div:nth-child(1)::after,
.hooks-main > div:nth-child(2)::after,
.hooks-main > div:nth-child(3)::after,
.hooks-main > div:nth-child(4)::after {
  position: absolute;
  content: "E";
  font-weight: 600;
  font-size: 1rem;
  color: white;
  top: 50%;
  left: 50%;
  filter: blur(0px);
  transform: translate(-50%, -50%);
}
.hooks-main > div:nth-child(2)::after {
  content: "L";
  font-size: 1rem;
}
.hooks-main > div:nth-child(3)::after {
  font-size: 1rem;
  content: "I";
}
.hooks-main > div:nth-child(4)::after {
  font-size: 0.8rem;
  content: "E";
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .welcome-box .headline-ws {
    font-size: 1.2rem;
  }

  .welcome-screen {
    background-color: rgba(0, 0, 0, 1);
  }

  /*  */
  @supports (backdrop-filter: blur(20px)) or
    (-webkit-backdrop-filter: blur(20px)) {
    /* NOW do stuff that requires filters to work */
    .welcome-screen {
      /*  */
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }
}

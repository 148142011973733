/* RULES */
/* section default flex direction in desktop row - override is allowed */
/* section will always align its content next to each other in deskop - override is allowed */
/* section will always align its content on top of each other in tab-mob - override is allowed */
/* in order for the viewport to take the full width of a section it should be added directly under the section element */
/* viewport by default justify content center - override is allowed */
/* viewport by default have a flex direction of column - override is allowed */

/* App style */
.App {
  width: 100%;
  overflow-x: hidden;
  /*  */
  -webkit-transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}

.main {
  position: relative;
  display: flex;
  flex: 1; /* use all available space */
  flex-direction: column; /* V axis */
  justify-content: flex-start; /* V axis alignment */
  align-items: stretch; /* cross axis alignment */
  min-height: 100vh;
}

/* main-body style */
.main-body {
  z-index: 2;
  flex-grow: 1; /* push footer down */
}

.header,
.main-body,
.footer {
  flex-shrink: 0;
}

/* section style */
.section {
  position: relative;
  display: flex;
  flex-direction: row; /* H axis */
  justify-content: center; /* H axis alignment */
  align-items: center; /* cross axis alignment */
}

/* viewport style */
.viewport {
  position: relative;
  display: flex;
  flex-direction: row; /* H axis */
  justify-content: center; /* H axis alignment */
  align-items: center; /* cross axis alignment */
  width: calc(100% - 60px);
  max-width: 1300px;
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .section,
  .viewport {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  /* viewport style */
  .viewport {
    width: calc(100% - 30px);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

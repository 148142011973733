/*Frame*/
.frame-top,
.frame-left,
.frame-right,
.frame-bottom {
  z-index: 20;
  position: fixed;
  pointer-events: none;
  opacity: 0.5;
}
.frame-top:before,
.frame-bottom:before,
.frame-top:after,
.frame-bottom:after,
.frame-left:before,
.frame-right:before,
.frame-left:after,
.frame-right:after {
  /*background-color: rgba(183, 31, 33, 1);*/
  background-color: #ff0000;
}
.frame-top,
.frame-bottom {
  width: calc(100% - 50px);
  height: 4px;
}
.frame-left,
.frame-right {
  width: 4px;
  height: calc(100% - 50px);
}
.frame-top,
.frame-left {
  top: 25px;
  left: 25px;
}
.frame-bottom {
  bottom: 25px;
  left: 25px;
}
.frame-right {
  top: 25px;
  right: 25px;
}
.frame-top:before,
.frame-bottom:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  /*background-color: rgba(240, 78, 89,0.7);*/
  background-color: #ff0000;
}
.frame-top:after,
.frame-bottom:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  /*background-color: rgba(64, 194, 210,0.7);*/
  background-color: #ff0000;
}
.frame-left:before,
.frame-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  /*background-color: rgba(119, 195, 131,0.7);*/
  background-color: #ff0000;
}
.frame-left:after,
.frame-right:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  /*background-color: rgba(255, 198, 59,0.7);*/
  background-color: #ff0000;
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .frame-top,
  .frame-left,
  .frame-right,
  .frame-bottom {
    z-index: 20;
    position: fixed;
    pointer-events: none;
    opacity: 0.5;
  }
  .frame-top:before,
  .frame-bottom:before,
  .frame-top:after,
  .frame-bottom:after,
  .frame-left:before,
  .frame-right:before,
  .frame-left:after,
  .frame-right:after {
    /*background-color: rgba(183, 31, 33, 1);*/
    background-color: #ff0000;
  }
  .frame-top,
  .frame-bottom {
    width: calc(100% - 30px);
    height: 3px;
  }
  .frame-left,
  .frame-right {
    width: 3px;
    height: calc(100% - 30px);
  }
  .frame-top,
  .frame-left {
    top: 15px;
    left: 15px;
  }
  .frame-bottom {
    bottom: 15px;
    left: 15px;
  }
  .frame-right {
    top: 15px;
    right: 15px;
  }
  .frame-top:before,
  .frame-bottom:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    /*background-color: rgba(240, 78, 89,0.7);*/
    background-color: #ff0000;
  }
  .frame-top:after,
  .frame-bottom:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    /*background-color: rgba(64, 194, 210,0.7);*/
    background-color: #ff0000;
  }
  .frame-left:before,
  .frame-right:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    /*background-color: rgba(119, 195, 131,0.7);*/
    background-color: #ff0000;
  }
  .frame-left:after,
  .frame-right:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    /*background-color: rgba(255, 198, 59,0.7);*/
    background-color: #ff0000;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

.text-rotator-box {
  position: relative;
  overflow: hidden;
  height: 40px;
  /*  */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
/*  */
@supports (backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) {
  /* NOW do stuff that requires filters to work */
  .text-rotator-box {
    /*  */
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.text-rotator-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.text-rotator-list-item {
  width: 100%;
  text-align: center;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 1.1rem;
}

/*Document */

html,
body {
  padding: 0;
  margin: 0;
  /*  */
  width: 100%;
  height: 100%;
  /*  */
  font-family: var(--primary-font);
  font-size: var(--font-size);
  color: var(--primary-font-color);
  line-height: 1.4;
  /*  */
  direction: ltr;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  /* overflow-x: hidden; */
  /* overscroll-behavior-y: contain; */
  user-select: none;
  background-color: black;

  /* no select */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

body.scroll-disabled {
  overflow: hidden;
}
/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

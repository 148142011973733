:root {
  /* colors */
  --primary-color: #f04e59;
  --secondary-color: #40c2d2;
  --tertiary-color: #ffc63b;
  --quaternary-color: #dc30e6;
  --quinary-color: #77c383;

  /* fonts */
  --primary-font: "Open Sans", sans-serif;
  --secondary-font: "Montserrat", sans-serif;
  --tertiary-font: "Roboto", sans-serif;
  --font-size: 14px;

  --primary-font-color: whitesmoke;
  --secondary-font-color: #cccccc;
  --tertiary-font-color: #888888;

  /* icons */
  --icons-lg: 2rem;
  --icons-md: 1.5rem;
  --icons-sm: 1rem;

  /* spacing */
  --spacing-unit-xsm: 5px;
  --spacing-unit-sm: 20px;
  --spacing-unit-md: 40px;
  --spacing-unit-lg: 60px;
  --spacing-unit-xlg: 120px;
  --spacing-unit-xxlg: 200px;

  /* Horizontal */
  --h-margin-ltr-xsm: 0 0 0 var(--spacing-unit-xsm);
  --h-margin-ltr-sm: 0 0 0 var(--spacing-unit-sm);
  --h-margin-ltr-md: 0 0 0 var(--spacing-unit-md);
  --h-margin-ltr-lg: 0 0 0 var(--spacing-unit-lg);

  --h-margin-rtl-xsm: 0 var(--spacing-unit-xsm) 0 0;
  --h-margin-rtl-sm: 0 var(--spacing-unit-sm) 0 0;
  --h-margin-rtl-md: 0 var(--spacing-unit-md) 0 0;
  --h-margin-rtl-lg: 0 var(--spacing-unit-lg) 0 0;

  --h-padding-ltr-sm: 0 0 0var (--spacing-unit-sm);
  --h-padding-ltr-md: 0 0 0var (--spacing-unit-md);
  --h-padding-ltr-lg: 0 0 0var (--spacing-unit-lg);

  --h-padding-rtl-sm: 0 var(--spacing-unit-sm) 0 0;
  --h-padding-rtl-md: 0 var(--spacing-unit-md) 0 0;
  --h-padding-rtl-lg: 0 var(--spacing-unit-lg) 0 0;

  /* Vertical */
  --v-margin-t-xsm: var(--spacing-unit-xsm) 0 0 0;
  --v-margin-t-sm: var(--spacing-unit-sm) 0 0 0;
  --v-margin-t-md: var(--spacing-unit-md) 0 0 0;
  --v-margin-t-lg: var(--spacing-unit-lg) 0 0 0;
  --v-margin-t-xlg: var(--spacing-unit-xlg) 0 0 0;
  --v-margin-t-xxlg: var(--spacing-unit-xxlg) 0 0 0;

  --v-margin-b-sm: 0 0 var(--spacing-unit-sm) 0;
  --v-margin-b-md: 0 0 var(--spacing-unit-md) 0;
  --v-margin-b-lg: 0 0 var(--spacing-unit-lg) 0;
  --v-margin-b-xlg: 0 0 var(--spacing-unit-xlg) 0;
  --v-margin-b-xxlg: 0 0 var(--spacing-unit-xxlg) 0;

  --v-margin-tb-sm: var(--spacing-unit-sm) 0 var(--spacing-unit-sm) 0;
  --v-margin-tb-md: var(--spacing-unit-md) 0 var(--spacing-unit-md) 0;
  --v-margin-tb-lg: var(--spacing-unit-lg) 0 var(--spacing-unit-lg) 0;
  --v-margin-tb-xlg: var(--spacing-unit-xlg) 0 var(--spacing-unit-xlg) 0;
  --v-margin-tb-xxlg: var(--spacing-unit-xxlg) 0 var(--spacing-unit-xxlg) 0;

  --v-padding-t-sm: var(--spacing-unit-sm) 0 0 0;
  --v-padding-t-md: var(--spacing-unit-md) 0 0 0;
  --v-padding-t-lg: var(--spacing-unit-lg) 0 0 0;
  --v-padding-t-xlg: var(--spacing-unit-xlg) 0 0 0;

  --v-padding-b-sm: 0 0 var(--spacing-unit-sm) 0;
  --v-padding-b-md: 0 0 var(--spacing-unit-md) 0;
  --v-padding-b-lg: 0 0 var(--spacing-unit-lg) 0;
  --v-padding-b-xlg: 0 0 var(--spacing-unit-xlg) 0;

  --v-padding-tb-sm: var(--spacing-unit-sm) 0 var(--spacing-unit-sm) 0;
  --v-padding-tb-md: var(--spacing-unit-md) 0 var(--spacing-unit-md) 0;
  --v-padding-tb-lg: var(--spacing-unit-lg) 0 var(--spacing-unit-lg) 0;
  --v-padding-tb-xlg: var(--spacing-unit-xlg) 0 var(--spacing-unit-xlg) 0;
}

.music-toggle-container {
  /*  border: 1px solid rgba(255, 255, 255, 0.2); */
  padding: 5px;
  padding-bottom: 8px;
  border-radius: 20px;

  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.volume-btn:disabled {
  color: #555555 !important;
}
/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

.fs-toggle {
  cursor: pointer;
  /*  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px; 
  width: 30px;
  height: 30px;*/
}

.fs-toggle svg {
  color: #fff;
  font-size: 2.5rem;

  padding: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

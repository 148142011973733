/*SocialMediaList*/
.social-media {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  margin-top: 0px;

  /* background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px; */
}

.social-media.large {
  font-size: 1.8rem;
}

.social-media.large li {
  padding: 10px 20px;
}

.social-media li {
  display: inline-block;
  padding: 10px;
}

.social-media li svg {
  color: #cccccc;
}
.social-media.large li svg {
  color: #fff;
}

.social-media li svg:hover {
  color: var(--tertiary-color);
}

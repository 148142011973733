.video-wrapper {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* type for each section */
.video-wrapper.screenType-1 .video-player {
  width: 100%;
  transform: translateY(0%);
}

.video-wrapper.screenType-2 .video-player {
  width: 100%;
  transform: translateY(0%);
}

.video-wrapper.screenType-3 .video-player {
  width: 100%;
  transform: translateY(-25%);
}

/* .video-player::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2xpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo5MDQ0Njk3QUI1QzgxMUU5Qjc5N0I0NEU5QjJDMDRCMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxMTI5NTI3QUI2MTUxMUU5OEFDMEI3QUU1MUY0NDZFMyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxMTI5NTI3OUI2MTUxMUU5OEFDMEI3QUU1MUY0NDZFMyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE4NDFEQTc5QjYxNDExRTlCMDkyREZFODFGOUYzNENGIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE4NDFEQTdBQjYxNDExRTlCMDkyREZFODFGOUYzNENGIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+pbS4EQAAABpJREFUeNpiYGBg+M8AATAahfOfgaAKgAADAANPB/lWKKsoAAAAAElFTkSuQmCC);
  background-repeat: repeat;
} */

.video-player::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

/*  */
.video {
  width: 100%;
  pointer-events: none;
}

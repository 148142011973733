.transition-fast {
  /**/
  -webkit-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}

.transition-medium {
  /**/
  -webkit-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
}
